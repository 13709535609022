import React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Link } from 'react-router-dom'
import Moment from 'react-moment'
import DeviceMenu from '../../Core/Device/DeviceMenu'
import { Typography } from '@mui/material'
import { generateUrl } from '../../../utils/generateUrl'


const StationList = ({ ap, stations }) => {


  const formatUptime = (uptime) => {
    const fm = [
      Math.floor(uptime / (3600 * 24)),
      Math.floor((uptime % (3600 * 24)) / 3600),
      Math.floor((uptime % 3600) / 60),
      Math.floor(uptime % 60),
    ]
    const formattedUptime = () =>
      fm
        .map((el, i) => {
          if (Boolean(el)) {
            if (i === 0) {
              el = el + 'd'
            } else if (i === 1) {
              el = el + 'h'
            } else if (i === 2) {
              el = el + 'm'
            } else if (i === 3) {
              el = el + 's'
            }
            return el
          }
        })
        .join('')
    return formattedUptime()
  }

  const waveColumns = [
    {
      field: ' ',
      maxWidth: 50,
      renderCell: (params) => (
        <DeviceMenu device={params.row} />
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Link
          style={{ textDecoration: 'inherit', color: 'inherit' }}
          to={generateUrl(params.row)}>
          {params.value}
        </Link>
      ),
    },
    {
      field: 'mac',
      headerName: 'MAC Address',
      flex: .75,
      minWidth: 100,
    },
    {
      field: 'ip',
      headerName: 'IP Address',
      flex: .75,
      minWidth: 110,
      renderCell: (params) => (
        <a
          style={{ textDecoration: 'inherit', color: 'inherit' }}
          target='_blank'
          rel="noreferrer"
          href={`http://${params.row?.ip}`}>
          {params.row.ip}
        </a>
      ),
    },
    {
      field: 'Radio',
      headerName: 'Radio',
      flex: .75,
      minWidth: 110,
      valueGetter: (params) => (params.row?.wireless?.activeRadio)
    },
    {
      field: 'expectedSignal',
      headerName: 'Expected Signal',
      flex: .65,
      valueGetter: (params) => (params.row?.wireless?.expectedSignal)
    },
    {
      field: 'signal',
      headerName: 'Signal',
      flex: .65,
      valueGetter: (params) => (params.row?.wireless?.signal)
    },
    {
      field: 'signalDelta',
      headerName: 'Signal Delta',
      flex: .65,
      valueGetter: (params) => (params.row?.wireless?.expectedSignal - params.row?.wireless?.signal)
    },
    {
      field: 'dlUtilization',
      headerName: 'dlUtilization',
      flex: .65,
      valueGetter: (params) => (params.row?.wireless?.dlUtilization)
    },
    {
      field: 'ulUtilization',
      headerName: 'ulUtilization',
      flex: .65,
      valueGetter: (params) => (params.row?.wireless?.ulUtilization)
    },
    {
      field: 'dlScore',
      headerName: 'dlScore',
      flex: .5,
      valueGetter: (params) => (params.row?.wireless?.dlScore)
    },
    {
      field: 'ulScore',
      headerName: 'ulScore',
      flex: .5,
      valueGetter: (params) => (params.row?.wireless?.ulScore)
    },
    {
      field: 'uptime',
      headerName: 'Uptime',
      flex: .75,
      minWidth: 100,
      renderCell: (params) => formatUptime(params.row?.uptime),
    },
    {
      field: 'lastContact',
      headerName: 'Status',
      flex: .75,
      minWidth: 100,
      renderCell: (params) => (
        <>
          {params.row?.connected ? (
            'Connected'
          ) : params.row?.lastContact ? (
            <Moment fromNow>{params.row?.lastContact}</Moment>
          ) : (
            <Typography>Never Connected</Typography>
          )}
        </>
      ),
      sortComparator: (v1, v2) => {
        return v1 > v2 ? -1 : 1
      },
    },
    {
      field: 'Distance',
      valueGetter: (params) => (
        `${params.row?.wireless?.distance / 1000}km`
      ),
    },
    {
      field: 'Firmware',
      valueGetter: (params) => (
        params.row?.firmware
      ),
    },
  ]

  const generalColumns = [
    {
      field: ' ',
      maxWidth: 50,
      renderCell: (params) => (
        <DeviceMenu device={params.row} />
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Link
          style={{ textDecoration: 'inherit', color: 'inherit' }}
          to={generateUrl(params.row)}>
          {params.value}
        </Link>
      ),
    },
    {
      field: 'mac',
      headerName: 'MAC Address',
      flex: .75,
      minWidth: 100,
    },
    {
      field: 'ip',
      headerName: 'IP Address',
      flex: .75,
      minWidth: 110,
      renderCell: (params) => (
        <a
          style={{ textDecoration: 'inherit', color: 'inherit' }}
          target='_blank'
          rel="noreferrer"
          href={`http://${params.row?.ip}`}>
          {params.row.ip}
        </a>
      ),
    },
    {
      field: 'expectedSignal',
      headerName: 'Expected Signal',
      flex: .65,
      valueGetter: (params) => (params.row?.wireless?.expectedSignal)
    },
    {
      field: 'signal',
      headerName: 'Signal',
      flex: .65,
      valueGetter: (params) => (params.row?.wireless?.signal)
    },
    {
      field: 'signalDelta',
      headerName: 'Signal Delta',
      flex: .65,
      valueGetter: (params) => (params.row?.wireless?.expectedSignal - params.row?.wireless?.signal)
    },
    {
      field: 'dlUtilization',
      headerName: 'dlUtilization',
      flex: .65,
      valueGetter: (params) => (params.row?.wireless?.dlUtilization)
    },
    {
      field: 'ulUtilization',
      headerName: 'ulUtilization',
      flex: .65,
      valueGetter: (params) => (params.row?.wireless?.ulUtilization)
    },
    {
      field: 'dlScore',
      headerName: 'dlScore',
      flex: .5,
      valueGetter: (params) => (params.row?.wireless?.dlScore)
    },
    {
      field: 'ulScore',
      headerName: 'ulScore',
      flex: .5,
      valueGetter: (params) => (params.row?.wireless?.ulScore)
    },
    {
      field: 'uptime',
      headerName: 'Uptime',
      flex: .75,
      minWidth: 100,
      renderCell: (params) => formatUptime(params.row?.uptime),
    },
    {
      field: 'lastContact',
      headerName: 'Status',
      flex: .75,
      minWidth: 100,
      renderCell: (params) => (
        <>
          {params.row?.connected ? (
            'Connected'
          ) : params.row?.lastContact ? (
            <Moment fromNow>{params.row?.lastContact}</Moment>
          ) : (
            <Typography>Never Connected</Typography>
          )}
        </>
      ),
      sortComparator: (v1, v2) => {
        return v1 > v2 ? -1 : 1
      },
    },
    {
      field: 'Distance',
      valueGetter: (params) => (
        `${params.row?.wireless?.distance / 1000}km`
      ),
    },
    {
      field: 'Firmware',
      valueGetter: (params) => (
        params.row?.firmware
      ),
    },
  ]

  return (
    <DataGrid
      initialState={{
        sorting: {
          sortModel: [{ field: 'name', sort: 'asc' }],
        }
      }}
      autoHeight
      rowHeight={30}
      getRowId={(row) => row._id}
      rows={stations}
      columns={ap.family === 'wave' ? waveColumns : generalColumns}
      pageSize={25}
      pageSizeOptions={[5, 10, 25]}
    />
  )
}

export default StationList