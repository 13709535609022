import React, { useState } from 'react'
import { useAppContext } from '../../../context/AppProvider'
import {
  Dialog,
  Typography,
  Grid,
  ListItemText,
  ListItem,
  List,
  Toolbar,
  AppBar,
} from '@mui/material'
import { Check, NotInterested } from '@mui/icons-material'

import AssignStepper from './AssignStepper'

const AssignDialog = ({ device, setAssign, assign }) => {
  const [subscriber, setSubscriber] = useState(null)
  const [service, setService] = useState(null)
  const [backbone, setBackbone] = useState(
    device.backbone ? device.backbone : false
  )

  return (
    <Dialog open={assign} onClose={() => setAssign(false)}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          {/* Left-aligned Assign text */}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Assign {device.name}
          </Typography>
        </Toolbar>
      </AppBar>
      <AssignStepper setAssign={setAssign} device={device} />
      {/* <SubInfoCard services={services} arps={arps} /> */}
      {!backbone && (
        <Grid container direction="row" justifyContent="space-between">
          <Grid
            item
            xs={6}
            sx={{ maxHeight: 'calc(100vh - 128px)', overflowY: 'auto' }}
          ></Grid>
          <Grid
            item
            xs={6}
            sx={{ maxHeight: 'calc(100vh - 128px)', overflowY: 'auto' }}
          >
            <List component="div" disablePadding>
              {subscriber &&
                subscriber?.services.map((svc) => (
                  <ListItem
                    key={svc.serviceId}
                    sx={{ pl: 4 }}
                    button
                    selected={svc.id === service?.id}
                    onClick={() => setService(svc)}
                    secondaryAction={
                      svc.status === 'Active' ? <Check /> : <NotInterested />
                    }
                  >
                    <ListItemText
                      primary={svc.accountName}
                      secondary={svc.serviceTypeDescription}
                    />
                    <List component="div" disablePadding>
                      {svc.charges.map((charge, index) => {
                        if (charge.chargeSubType === 'SVC') {
                          return (
                            <ListItem key={index} sx={{ pl: 4 }}>
                              <ListItemText
                                primary={`${charge.description}`}
                                secondary={`Price: ${charge.price}`}
                              />
                            </ListItem>
                          )
                        }
                      })}
                    </List>
                  </ListItem>
                ))}
            </List>
          </Grid>
        </Grid>
      )}
    </Dialog>
  )
}

export default AssignDialog
