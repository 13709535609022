import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import {
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import ZoomableAreaChart from '../../Charts/ZoomableAreaChart'
import EventList from '../../Core/EventList'
import StationList from './StationList'
import { resetHistoricalData } from '../../../reduxSlices/wsHistoricalDataSlice'
import { useAppContext } from '../../../context/AppProvider'

const ChartTabs = ({ device, ap, sta, stas }) => {
  const dispatch = useDispatch()

  const { ws } = useAppContext()
  const { events } = useSelector((state) => state.wsEvents)
  const history = useSelector((state) => state.wsHistoricalData)
  const [value, setValue] = useState('1')
  const [timeFrame, setTimeFrame] = useState(4)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    if (sta) {
      ws.fetchHistoricalData(sta.mac, timeFrame)
    } else if (ap) {
      ws.fetchHistoricalData(ap.mac, timeFrame)
    }
    return () => {
      dispatch(resetHistoricalData())
    }
  }, [ap?._id, sta?._id, timeFrame])

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Stations" value="1" />
                <Tab label="History" value="2" />
                <Tab label="Logs" value="3" />
              </TabList>
            </Grid>
            <Grid item>{history.loading ? <CircularProgress /> : null}</Grid>
            <Grid item>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Time Frame
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={timeFrame}
                  label="Time Frame"
                  onChange={(e) => setTimeFrame(e.target.value)}
                >
                  <MenuItem value={2}>2 Hours</MenuItem>
                  <MenuItem value={4}>4 Hours</MenuItem>
                  <MenuItem value={8}>8 Hours</MenuItem>
                  <MenuItem value={24}>24 Hours</MenuItem>
                  <MenuItem value={48}>2 Days</MenuItem>
                  <MenuItem value={72}>3 Days</MenuItem>
                  <MenuItem value={168}>1 Week</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <TabPanel value="1">
          {ap && stas && <StationList ap={ap} stations={stas} />}
        </TabPanel>
        <TabPanel value="2">
          {history.data ? (
            <>
              <ZoomableAreaChart
                historicalData={history.data}
                graphs={[
                  {
                    title: 'Utilization',
                    dataPoints: [
                      {
                        type: 'area',
                        name: 'Download Utilization',
                        dataKey: 'dlUtilization',
                        unit: '%',
                        color: '#38b6ff', // Brighter blue for contrast
                      },
                      {
                        type: 'area',
                        name: 'Upload Utilization',
                        dataKey: 'ulUtilization',
                        unit: '%',
                        color: '#33cc99', // Seafoam green
                      },
                    ],
                  },
                  {
                    title: 'Download',
                    dataPoints: [
                      {
                        type: 'area',
                        name: 'Download Capacity',
                        dataKey: 'dlCapacity',
                        unit: 'Mbps',
                        color: '#4d88ff', // Teal blue
                      },
                      device?.function === 'ap-ptmp' && {
                        type: 'area',
                        name: 'Download Range',
                        dataKey: 'dlThroughputRange',
                        unit: 'Mbps',
                        color: '#668cff', // Cornflower blue
                      },
                      {
                        type: 'line',
                        name: 'Download Average',
                        dataKey: 'dlThroughput',
                        unit: 'Mbps',
                        color: '#668cff', // Cornflower blue (same as Download Range for consistency)
                      },
                    ],
                  },
                  {
                    title: 'Upload',
                    dataPoints: [
                      {
                        type: 'area',
                        name: 'Upload Capacity',
                        dataKey: 'ulCapacity',
                        unit: 'Mbps',
                        color: '#99cc66',
                      },
                      device?.function === 'ap-ptmp' && {
                        type: 'area',
                        name: 'Upload Range',
                        dataKey: 'ulThroughputRange',
                        unit: 'Mbps',
                        color: '#b3cc66',
                      },
                      {
                        type: 'line',
                        name: 'Upload Throughput',
                        dataKey: 'ulThroughput',
                        unit: 'Mbps',
                        color: '#ff6666',
                      },
                    ],
                  },
                  device?.function === 'ap-ptmp'
                    ? {
                        title: 'Scores',
                        dataPoints: [
                          {
                            type: 'area',
                            name: 'Download Score',
                            dataKey: 'dlScore',
                            unit: '%',
                            color: '#a1887f',
                          },
                          {
                            type: 'area',
                            name: 'Upload Score',
                            dataKey: 'ulScore',
                            unit: '%',
                            color: '#cc6666',
                          },
                        ],
                      }
                    : {
                        title: 'signal',
                        dataPoints: [
                          {
                            type: 'line',
                            name: 'Expected Signal',
                            dataKey: 'expectedSignal',
                            unit: 'dB',
                            color: '#ffcc66',
                          },
                          {
                            type: 'line',
                            name: 'Signal',
                            dataKey: 'signal',
                            unit: 'dB',
                            color: '#ffcc99',
                          },
                        ],
                      },
                ]}
              />
            </>
          ) : null}
        </TabPanel>
        <TabPanel value="3">{events && <EventList events={events} />}</TabPanel>
      </TabContext>
    </Box>
  )
}

export default ChartTabs
