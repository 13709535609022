import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import { useSelector } from 'react-redux'
import { OpenInNew } from '@mui/icons-material/'
import {
  Typography,
  Grid,
  Paper,
  Box,
  Collapse,
  Checkbox,
  Select,
  MenuItem,
  Divider,
  Stack,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { Bolt, FlashAuto } from '@mui/icons-material/'
import { useUptime } from '../../../hooks'
import DeviceMenu from '../../Core/Device/DeviceMenu'

const SwOS = ({ devices, device, edges }) => {
  const theme = useTheme()
  const uptime = useUptime(device.uptime)

  const [collapsed, setCollapsed] = useState(false)
  const [editRowsModel, setEditRowsModel] = useState({})

  const handleEditRowsModelChange = React.useCallback((model) => {
    setEditRowsModel(model)
  }, [])

  const data = device?.switching?.interfaces

  const InterfaceBox = ({ iface }) => {
    const {
      enabled,
      name,
      duplex,
      rxRate,
      txRate,
      tagging,
      untagging,
      currentSpeed,
      poe,
    } = iface

    const boxColor = () => {
      switch (currentSpeed) {
        case '10G':
          return 'white'
        case '1G':
          return 'green'
        case '100M':
          return 'orange'
        case '10M':
          return 'red'
        default:
          return 'grey'
      }
    }
    return (
      <Box
        sx={{
          backgroundColor: boxColor(),
          height: '20px',
          width: '20px',
        }}>
        {
          poe === 'active' || poe === 'auto' || poe === 'waiting for load'
            ? (
              <FlashAuto fontSize='small' />
            )
            : poe === 'on' || poe === 'powered on' ? (
              <Bolt fontSize='small' />
            )
              : null
        }
      </Box>
    )
  }

  const columns = [
    // {
    //   field: 'id',
    //   headerName: 'Interface',
    //   flex: 0.25,
    //   valueGetter: (params) => params?.row?.id,
    // },
    {
      field: 'name',
      headerName: 'Name',
      editable: true,
      flex: 1,
      renderCell: (params) => {
        const connectedEdges = edges
          .map((edge) => {
            if (edge.flipped) {
              return { ...edge, from: edge.to, to: edge.from }
            }
            return edge
          })
          .filter((edge) => edge?.from === device._id.toString())
        const connectedEdge = connectedEdges.find(el => el.port === (params?.row?.id - 1).toString())
        const connectedDevice = devices.find(device => device._id.toString() === connectedEdge?.to)

        const ifaceName = params.row.name
        return (
          connectedDevice?.name ? (
      <Link
        style={{ textDecoration: 'inherit', color: connectedDevice?.connected ? theme.palette.primary.main : theme.palette.secondary.main }}
        to={`/network/device/${connectedDevice?._id}`}
      >
        <Stack direction='row' spacing={2} sx={{ justifyContent: "space-between", alignItems: "center" }}>
          <Typography>{`${connectedDevice?.name}  `}</Typography>
          <OpenInNew fontSize='small' />
        </Stack>
        
      </Link>
    ) : ifaceName ? (
      <Typography>{ifaceName}</Typography>
    ) : (
      <Typography color={'lightgray'}>{`Port ${params?.row?.id.split('/')[1]}`}</Typography>
    )
        )
      },
    },
    {
      field: 'poe',
      headerName: 'POE',
      flex: 0.5,
      valueGetter: (params) => params?.row?.poe,
    },
    {
      field: 'status',
      headerName: 'Speed',
      flex: 0.25,
      valueGetter: (params) => params?.row?.currentSpeed
    },
    {
      field: 'dhcpSnooping',
      headerName: 'DHCP Snooping',
      flex: 0.25,
      renderCell: (params) => (
        <Checkbox size="small" checked={params?.row?.dhcpSnooping} />
      ),
    },
    {
      field: 'enabled',
      headerName: 'Enabled',
      flex: 0.25,
      renderCell: (params) => (
        <Checkbox size="small" checked={params?.row?.enabled} />
      ),
    },
  ]

  return (
    <Paper elevation={5} sx={{ p: 1, flexGrow: 1 }}>
      <Grid
        container
        backgroundColor={!device.connected && 'red'}
        direction='column'
        sx={{ p: 2 }}
        justifyContent='space-evenly'>
        <Grid item>
          <Grid item container direction='row' justifyContent='space-between'>
            <Grid item>
              <Typography variant='h6'>{device.name}</Typography>
            </Grid>
            <Grid item>
              <DeviceMenu device={device} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction='row' justifyContent='space-between'>
          <Grid item>
            <Typography variant='caption' component='div'>
              {uptime}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid
          item
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          onClick={() => setCollapsed(!collapsed)}>
          <Grid item>
            <Grid
              item
              container
              direction='column'
              justifyContent='space-between'>
              <Grid item>
                <Typography>{device.model}</Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <a
                    onClick={(e) => e.stopPropagation()}
                    style={{
                      textDecoration: 'inherit',
                      color: 'inherit',
                    }}
                    target='_blank'
                    href={`http://${device.ip}`}>
                    {device.ip}
                  </a>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='caption' component='div'>
                  {device?.mac?.toUpperCase()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Paper elevation={0} sx={{ p: 1, flexGrow: 1 }}>
              <Grid container direction='column'>
                <Grid item container direction='row'>
                  {device?.switching?.interfaces
                    ?.filter((iface) => iface.id % 2 !== 0)
                    .map((iface) => (
                      <Grid item key={iface.id}>
                        <Box sx={{ pr: 0.5, pl: 0.5 }}>
                          <Typography
                            component='div'
                            align='center'
                            variant='caption'>
                            {iface.id}
                          </Typography>
                          <InterfaceBox iface={iface} />
                        </Box>
                      </Grid>
                    ))}
                </Grid>
                <Divider />
                <Grid item container direction='row'>
                  {device?.switching?.interfaces
                    ?.filter((iface) => iface.id % 2 === 0)
                    .map((iface) => (
                      <Grid item key={iface.id}>
                        <Box sx={{ pr: 0.5, pl: 0.5 }}>
                          <InterfaceBox iface={iface} />
                          <Typography
                            component='div'
                            align='center'
                            variant='caption'>
                            {iface.id}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Divider />
        <Grid item>
          {
            device.switching?.interfaces &&
            <Collapse in={collapsed}>
              <div style={{ display: 'flex' }}>
                <div style={{ flexGrow: 1 }}>
                  <DataGrid
                    autoHeight
                    disableSelectionOnClick
                    editRowsModel={editRowsModel}
                    onEditRowsModelChange={handleEditRowsModelChange}
                    getRowId={(row) => row.id}
                    getRowHeight={() => 'auto'}
                    rows={data}
                    columns={columns}
                    sx={{
                      '& .MuiDataGrid-columnHeaderTitle': {
                        textOverflow: 'clip',
                        whiteSpace: 'break-spaces',
                        lineHeight: 1,
                      },
                      '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                        outline: 'none',
                      },
                    }}
                  />
                </div>
              </div>
            </Collapse>
          }
        </Grid>
      </Grid>
    </Paper>
  )
}

export default SwOS
