import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  buildMap,
  parseNoise,
  signalCompare,
} from '../utils/wirelessParserFunctions'

import {
  Typography,
  Grid,
  Paper,
  Box,
  LinearProgress,
  Stack,
  Tooltip,
  CircularProgress,
} from '@mui/material'
import { useTheme } from '@emotion/react'
import { useGetDevice, useJoinDeviceRoom, useStaTunnel } from '../hooks'
import WirelessDeviceCard from '../Components/Network/LTUDashboard/WirelessDeviceCard'
import WirelessDetailCard from '../Components/Network/LTUDashboard/WirelessDetailCard'
import NetworkMap from '../Components/Maps/Geolocation/NetworkMap'
import ChartTabs from '../Components/Network/LTUDashboard/ChartTabs'
import WirelessBar from '../Components/Network/LTUDashboard/WirelessBar'
import { useAppContext } from '../context/AppProvider'

const WirelessScreen = () => {
  const { ws } = useAppContext()
  const theme = useTheme()
  const { id } = useParams()
  const timer = useRef()

  const { ap, stas, site, loading } = useSelector((state) => state.wsDevice)
  const wsNoiseData = useSelector((state) => state.wsNoiseData)

  const [sta, setSta] = useState(null)
  const [live, setLive] = useState(false)

  let apNoise = wsNoiseData?.data?.ssd[0]?.pwr_avgs
  let stasNoise =
    wsNoiseData?.data?.ssd[wsNoiseData?.data?.ssd.length - 1]?.pwr_avgs

  useEffect(() => {
    setLive(true)
    clearTimeout(timer.current)
    if (stas) {
      const station = stas.find((sta) => sta._id === id)
      setSta(station)
    }
    timer.current = setTimeout(() => {
      setLive(false)
    }, 10000)
  }, [ap, stas])

  const mapData = () => {
    let data
    if (sta) {
      data = { ...sta }
      data.site = site
    } else {
      data = { ...ap }
      data.site = site
    }
    data.stations = stas
    return data
  }

  useGetDevice(id)
  useJoinDeviceRoom(id)
  useStaTunnel(ap?._id, sta)

  // useEffect(() => {
  //   if (ap?._id) {
  //     ws.joinDeviceRoom(ap._id)
  //     return () => {
  //       ws.leaveDeviceRoom(ap._id)
  //     }
  //   }
  // }, [])

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <WirelessBar ap={ap} sta={sta} site={site} live={live} />
          <Box sx={{ p: 1 }}>
            <Paper>
              <Grid container direction="column">
                <Grid item container direction="row">
                  <Grid
                    sx={{ bgcolor: ap?.connected ? null : 'darkred' }}
                    item
                    xs={6}
                    md={4}
                    lg={3}
                    xl={2}
                    order={{ xs: 2, md: 1 }}
                  >
                    <WirelessDeviceCard data={ap} />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={6}
                    xl={8}
                    order={{ xs: 1, md: 2 }}
                  >
                    {site.length && <NetworkMap sites={buildMap(mapData())} />}
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={4}
                    lg={3}
                    xl={2}
                    order={{ xs: 2, md: 3 }}
                  >
                    {sta ? (
                      <WirelessDeviceCard data={sta} />
                    ) : (
                      <WirelessDetailCard data={ap} />
                    )}
                  </Grid>
                </Grid>
                <Grid item container direction="row">
                  <Grid item xs={12} sm={6} lg={3} order={{ xs: 2, lg: 1 }}>
                    {sta && <WirelessDetailCard data={ap} />}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    lg={6}
                    order={{ xs: 1, sm: 1, lg: 2 }}
                    sx={{ width: '100%', flexGrow: 1 }}
                  >
                    <Grid item container direction="column">
                      {apNoise && (
                        <Grid item>
                          <Paper elevation={5} sx={{ p: 1, flexGrow: 1 }}>
                            <Typography>AP Noise</Typography>
                            <Stack direction="row">
                              {parseNoise(ap, apNoise).map((el, i) => (
                                <Stack sx={{ flexGrow: 1 }} key={i}>
                                  {el.utilized && (
                                    <Box
                                      sx={{
                                        flexGrow: 1,
                                        backgroundColor: 'black',
                                        height: '3px',
                                      }}
                                    />
                                  )}
                                  <Tooltip
                                    title={
                                      <>
                                        <Typography>{el.channel}MHz</Typography>
                                        <Typography align="center">
                                          {el.noise - 125}dBm
                                        </Typography>
                                      </>
                                    }
                                  >
                                    <Box
                                      sx={{
                                        flexGrow: 1,
                                        backgroundColor: el.color,
                                        height: '30px',
                                      }}
                                    />
                                  </Tooltip>
                                  {el.utilized && (
                                    <Box
                                      sx={{
                                        flexGrow: 1,
                                        backgroundColor: 'black',
                                        height: '3px',
                                      }}
                                    />
                                  )}
                                </Stack>
                              ))}
                            </Stack>
                          </Paper>
                        </Grid>
                      )}
                      {stasNoise && (
                        <Grid item>
                          <Paper elevation={5} sx={{ p: 1, flexGrow: 1 }}>
                            <Typography>Station Noise</Typography>
                            <Stack direction="row">
                              {parseNoise(ap, stasNoise)?.map((el, i) => (
                                <Stack sx={{ flexGrow: 1 }} key={i}>
                                  {el.utilized && (
                                    <Box
                                      sx={{
                                        flexGrow: 1,
                                        backgroundColor: 'black',
                                        height: '3px',
                                      }}
                                    />
                                  )}
                                  <Tooltip
                                    title={
                                      <>
                                        <Typography>{el.channel}MHz</Typography>
                                        <Typography align="center">
                                          {el.noise - 125}dBm
                                        </Typography>
                                      </>
                                    }
                                  >
                                    <Box
                                      sx={{
                                        flexGrow: 1,
                                        backgroundColor: el.color,
                                        height: '30px',
                                      }}
                                    />
                                  </Tooltip>
                                  {el.utilized && (
                                    <Box
                                      sx={{
                                        flexGrow: 1,
                                        backgroundColor: 'black',
                                        height: '3px',
                                      }}
                                    />
                                  )}
                                </Stack>
                              ))}
                            </Stack>
                          </Paper>
                        </Grid>
                      )}
                      {ap?.wireless?.dlUtilization && (
                        <Grid item>
                          <Paper elevation={5} sx={{ p: 1, flexGrow: 1 }}>
                            <Typography>AP Utilization</Typography>
                            <LinearProgress
                              color="success"
                              sx={{ height: '5px' }}
                              variant="buffer"
                              valueBuffer={ap?.wireless?.dlUtilization}
                              value={sta ? sta?.wireless?.dlUtilization : 0}
                            />
                            <LinearProgress
                              color="secondary"
                              sx={{
                                height: '5px',
                                transform: 'rotate(180deg)',
                              }}
                              variant="buffer"
                              valueBuffer={ap?.wireless?.ulUtilization}
                              value={sta ? sta?.wireless?.ulUtilization : 0}
                            />
                          </Paper>
                        </Grid>
                      )}
                      {sta && (
                        <Grid item container direction="row">
                          <Grid item xs={12} container direction="column">
                            {
                              sta?.family === 'wave' ? (
                                <>
                                  {
                                    sta.wireless?.radios?.map((radio, i) => (
                                      <Paper elevation={5} sx={{ p: 1, flexGrow: 1 }}>
                                    <Grid
                                      item
                                      xs
                                      container
                                      direction="row"
                                      justifyContent="space-between"
                                    >
                                      <Grid item xs>
                                      <Grid item xs>
                                        <Stack>
                                        <Typography>{radio.name}</Typography>
                                        <Typography color={radio.linkState === 'active' || radio.linkState === 'connected' ? 'green' : 'red'} variant="body1">{radio.linkState}</Typography>
                                        </Stack>
                                      </Grid>
                                      </Grid>
                                      <Grid item xs>
                                        <Box>
                                          <Typography
                                            variant="h3"
                                            color={signalCompare(
                                              radio.linkQuality?.idealSignal,
                                              radio.linkQuality?.signal
                                            )}
                                            align="center"
                                          >
                                            {radio.linkQuality?.signal}
                                          </Typography>
                                        </Box>
                                      </Grid>
                                      <Grid item xs>
                                        {radio.linkQuality?.idealSignal && (
                                          <Typography align="right">
                                            Expected {radio.linkQuality?.idealSignal}
                                            dBm
                                          </Typography>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Paper>
                                    ))
                                  }
                                </>
                              ) : (
                                <Paper elevation={5} sx={{ p: 1, flexGrow: 1 }}>
                                  <Grid
                                    item
                                    xs
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                  >
                                    <Grid item xs>
                                      <Typography>CPE Signal</Typography>
                                    </Grid>
                                    <Grid item xs>
                                      <Box>
                                        <Typography
                                          variant="h3"
                                          color={signalCompare(
                                            sta?.wireless?.expectedSignal,
                                            sta?.wireless?.signal
                                          )}
                                          align="center"
                                        >
                                          {sta?.wireless?.signal}
                                        </Typography>
                                      </Box>
                                    </Grid>
                                    <Grid item xs>
                                      {sta?.wireless?.expectedSignal && (
                                        <Typography align="right">
                                          Expected {sta?.wireless?.expectedSignal}
                                          dBm
                                        </Typography>
                                      )}
                                    </Grid>
                                  </Grid>
                                  {sta?.wireless?.signalScore && (
                                    <Grid item>
                                      <LinearProgress
                                        color="success"
                                        sx={{ height: '5px' }}
                                        variant="determinate"
                                        value={sta?.wireless?.signalScore}
                                      />
                                    </Grid>
                                  )}
                                </Paper>
                              )
                            }
                          </Grid>
                        </Grid>
                      )}
                      {ap?.family === 'aviat' && (
                        <Grid item container direction="row">
                          <Grid item xs={12} container direction="column">
                            <Paper elevation={5} sx={{ p: 1, flexGrow: 1 }}>
                              <Grid
                                item
                                xs
                                container
                                direction="row"
                                justifyContent="space-between"
                              >
                                <Grid item xs>
                                  <Typography>Signal</Typography>
                                </Grid>
                                <Grid item xs>
                                  <Box>
                                    <Typography
                                      variant="h3"
                                      color={signalCompare(
                                        ap?.wireless?.expectedSignal,
                                        ap?.wireless?.signal
                                      )}
                                      align="center"
                                    >
                                      {ap?.wireless?.signal}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs>
                                  {ap?.wireless?.expectedSignal && (
                                    <Typography align="right">
                                      Expected {ap?.wireless?.expectedSignal}dBm
                                    </Typography>
                                  )}
                                </Grid>
                              </Grid>
                              {ap?.wireless?.expectedSignal && (
                                <Grid item>
                                  <LinearProgress
                                    color="success"
                                    sx={{ height: '5px' }}
                                    variant="determinate"
                                    value={Math.round(
                                      (ap?.wireless?.expectedSignal /
                                        ap?.wireless?.signal) *
                                        100
                                    )}
                                  />
                                </Grid>
                              )}
                            </Paper>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {sta && (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      lg={3}
                      order={{ xs: 2, sm: 2, lg: 3 }}
                    >
                      <WirelessDetailCard data={sta} />
                    </Grid>
                  )}
                </Grid>
                <Grid item container direction="column">
                  <Grid item xs>
                    <Paper elevation={5} sx={{ p: 1, flexGrow: 1 }}>
                      <ChartTabs
                        device={sta ? sta : ap}
                        ap={ap}
                        sta={sta}
                        stas={stas}
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </>
      )}
    </>
  )
}

export default WirelessScreen
