import React, { useEffect, useState, useMemo } from 'react'
import { Button, Grid, Box } from '@mui/material'
import VisGraph from 'react-vis-graph-wrapper'


const SiteTopology = ({ siteNodes, siteEdges }) => {
  const { nodes, edges } = useMemo(() => {
    const newNodes = siteNodes.map(({ _id, name, connected }) => ({
      id: _id.toString(),
      label: name,
      color: connected ? '#23C766' : '#EF4B56',
    }))

    const newEdges = siteEdges
      .map((el) => ({
        to: el.to,
        from: el.from,
        //  label: el.port,
        color: 'green',
      }))

    return { nodes: newNodes, edges: newEdges }
  }, [siteNodes, siteEdges])


  const options = useMemo(() => ({
    layout: {
      randomSeed: '0.5015997545281881:1674586708474', //seed,
      improvedLayout: true
    },
    physics: {
      forceAtlas2Based: {
        springLength: 230,
        springConstant: 0.18,
      },
      maxVelocity: 146,
      solver: "forceAtlas2Based",
      timestep: 0.35,
      stabilization: { iterations: 150 },
    },
    nodes: {
      shape: "dot",
      font: { color: 'white' }
    },
    edges: {
      smooth: true,
    },
  }), [siteNodes, siteEdges])

  return (
    <Grid container direction="column">
      <Grid item xs>
        <Box sx={{ position: 'absolute', p: 1, height: '50vh', width: '50vh', overflow: 'hidden', }}>
          {nodes.length > 0 && (
            <VisGraph
              graph={{ nodes, edges }}
              options={options}
              style={{ height: '100%', width: '100%' }}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

export default SiteTopology