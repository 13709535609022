import * as React from 'react'
import { useAppContext } from '../../../context/AppProvider'

import { AutoMode } from '@mui/icons-material/'
import { Tooltip } from '@mui/material'


const CycleIface = ({ device, iface }) => {
  const { ws } = useAppContext()

  const handleCycle = () => {
    ws.cycleIface({device, iface})
  }

  return (
    <Tooltip title="Reset POE and Link" arrow>
      <AutoMode onClick={handleCycle} />    
    </Tooltip>
    
  )
}

export default CycleIface